<div class="container-fluid content-wrap" [ngClass]="{ 'sidebar-closed': hideSidebar }">
    <div class="row">
        <nav class="sidebar-1" *ngIf="!hideSidebar">
            <a class="navbar-brand-1" routerLink="/">
                <img class="sidebar-logo-1" src="{{this.logo}}" alt="">
            </a>

            <div class="profile">
                <!-- <div class="profile-image">
                    <div class="profile-image-in"  style="background-image:url({{avatarImage}})"></div>
                </div> -->
                <div class="profile-info">
                    <div class="profile-name">{{user.UserName}}
                        <a 
                            *ngIf="rights.can('permission-notification-settings')" 
                            title="Manage notification settings"
                            routerLink="/users/notification-settings"
                        ><i class="fa fa-bell-slash" aria-hidden="true" class="fa fa-cog pointer"></i></a>
                    </div>
                    <div>
                        {{user.userMedicalCenter.MedicalCenterName}}
                        <a 
                            *ngIf="rights.can('change-medical-center-alert-settings')" 
                            (click)="showMedicalCenterAlertSettings = !showMedicalCenterAlertSettings"
                            title="Manage alarm settings"
                        ><i class="fa fa-bell" aria-hidden="true"></i></a>
                        <app-alert-settings 
                        *ngIf="showMedicalCenterAlertSettings"
                        (closeAlertSettings)="showMedicalCenterAlertSettings = false" 
                        [medicalCenterID]="user.userMedicalCenter.MedicalCenterID">
                        </app-alert-settings>
                    </div>
                    <div>
                        <span class="pointer" (click)="editProfile();" translate>Profile</span>
                        <span class="vertical-separator">|</span>
                        <span class="pointer" (click)="exit();" translate>Exit</span>
                    </div>
                </div>
            </div>

            <div class="profile-search" *ngIf="medicalCenters.length > 0">
                <select [formControl]="medicalCenter" (ngModelChange)="changeMedicalCenter($event)"
                        class="custom-select form-control" required>
                    <option *ngIf="medicalCenters.length > 1" value="" translate>All</option>
                    <option *ngFor="let medicalCenter of medicalCenters"
                            [ngValue]="+medicalCenter.MedicalCenterID">{{medicalCenter.MedicalCenterName}}
                    </option>
                </select>
            </div>

            <!-- <ul class="nav flex-column tasks-and-comments">
                <li class="nav-item">
                    <a class="nav-link" routerLink="#" routerLinkActive="active">
                        <img src="/assets/images/left-side-bar/tasks1.png" alt="">
                        {{'Task and notes' | translate}}
                    </a>
                </li>
            </ul> -->
            <ng-template
            [ngIf]="this.rights.can([
            'permission-doctor-schedule'
            ]) && isLoggedUserDoctor"
            >
                <h6
                    class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1">
                    <span>{{'Schedule' | translate}}</span>
                    <a class="d-flex align-items-center " href="#">
                        <span data-feather="plus-circle"></span>
                    </a>
                </h6>
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/schedule/my-schedule/{{doctorUserID}}" routerLinkActive="active">
                            <i class="far fa-calendar" aria-hidden="true"></i>
                            {{'My Schedule' | translate }}
                        </a>
                    </li>
                </ul>
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/schedule/appointments-list" routerLinkActive="active">
                            <i class="fas fa-tasks" aria-hidden="true"></i>
                            {{'Appointments List' | translate }}
                        </a>
                    </li>
                </ul>
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/schedule/history" routerLinkActive="active">
                            <i class="fa fa-history" aria-hidden="true"></i>
                            {{'Calls History' | translate }}
                        </a>
                    </li>
                </ul>
            </ng-template>
            <ng-template
            [ngIf]="this.rights.can([
            'permission-read-ambulatory-monitoring'
            ])"
            >
                <h6
                    class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1">
                    <span>{{'PATIENTS' | translate}}</span>
                    <a class="d-flex align-items-center " href="#">
                        <span data-feather="plus-circle"></span>
                    </a>
                </h6>
                <ul class="nav flex-column">
                    <li class="nav-item"  *ngIf="this.rights.can([
                    'long-patient-admission'
                    ])">
                        <a class="nav-link" routerLink="/patients/new" routerLinkActive="active">
                            <img class="eyeclass" src="/assets/images/left-side-bar/add_patient.svg" alt="">
                            {{'Add new patient' | translate }}
                        </a>
                    </li>
                    <li class="nav-item"  *ngIf="this.rights.canPermission([
                    'short-patient-admission'
                    ])">
                        <a class="nav-link" routerLink="/patients/new-short" routerLinkActive="active">
                            <img class="eyeclass" src="/assets/images/left-side-bar/add_patient.svg" alt="">
                            <ng-template [ngIf]="this.rights.can([
                            'long-patient-admission'
                            ])" [ngIfElse]="longAdmissionText">
                                {{'Short patient admission' | translate }}
                            </ng-template>
                            
                            <ng-template #longAdmissionText>
                                {{'Add new patient' | translate }}
                            </ng-template>
                            
                            
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/patients/list" routerLinkActive="active">
                            <img src="/assets/images/left-side-bar/patients_list.svg" alt="">
                            {{'Patient management' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="this.rights.can([
                    'permission-write-questionary', 'permission-read-questionary'
                    ])">
                        <a class="nav-link" routerLink="/questionnaires" routerLinkActive="active">
                            <i class="fas fa-tasks"></i>
                            {{'Questionnaires' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="this.rights.can([
                    'permission-write-question', 'permission-read-question'
                    ])">
                        <a class="nav-link" routerLink="/questions" routerLinkActive="active">
                            <i class="far fa-question-circle"></i>
                            {{'Questions' | translate }}
                        </a>
                    </li>
                </ul>
            </ng-template>
            <ng-template
                [ngIf]="this.rights.can([
                        'permission-read-users', 'permission-write-users',
                        'permission-write-medical-centers', 'permission-read-medical-centers',
                        'permission-read-medical-staff', 'permission-write-medical-staff',
                        'permission-view-doctor-reviews'
                        ])">
                <h6
                    class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 ">
                    <span>{{'Users' | translate }}</span>
                    <a class="d-flex align-items-center " href="#">
                        <span data-feather="plus-circle"></span>
                    </a>
                </h6>
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a class="nav-link"
                            *ngIf="this.rights.can(['permission-write-medical-centers', 'permission-read-medical-centers'])"
                            routerLink="/medical-centers" routerLinkActive="active">
                            <img src="/assets/images/left-side-bar/tasks7.png" alt="">
                            {{'Medical centers' | translate }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"
                            *ngIf="this.rights.can(['permission-write-medical-staff'])"
                            routerLink="/medical-staff" routerLinkActive="active">
                            <i aria-hidden="true" class="fas fa-user-md fa-lg"></i>
                            {{'Medical staff' | translate }}
                        </a>
                    </li>
                    <li class="nav-item"
                        *ngIf="this.rights.can(['permission-read-users', 'permission-write-users'])">
                        <a class="nav-link" routerLink="/users" routerLinkActive="active">
                            <i aria-hidden="true" class="fas fa-user-check fa-lg"></i>
                            {{'Accounts' | translate }}
                        </a>
                    </li>
                    <li class="nav-item"
                        *ngIf="this.rights.can(['permission-write-roles', 'permission-write-users', 'permission-read-users'])">
                        <a class="nav-link" routerLink="/users/roles" routerLinkActive="active">
                            <i aria-hidden="true" class="fas fa-user-cog fa-lg"></i>
                            {{'Roles' | translate }}
                        </a>
                    </li>
                    <li class="nav-item"
                        *ngIf="this.rights.can(['permission-view-doctor-reviews'])">
                        <a class="nav-link" routerLink="/doctor-reviews" routerLinkActive="active">
                            <i aria-hidden="true" class="fa fa-comments fa-lg"></i>
                            {{'Doctor Reviews' | translate }}
                        </a>
                    </li>
                </ul>
            </ng-template>
            <ng-template [ngIf]="this.rights.can('permission-view-live-data')">
                <h6
                    class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 ">
                    <span>{{'Monitoring screens' | translate }}</span>
                    <a class="d-flex align-items-center " href="#">
                        <span data-feather="plus-circle"></span>
                    </a>
                </h6>
                <ul class="nav flex-column">
                    <li class="nav-item" *ngIf="this.rights.can(['permission-view-icu-monitoring'])">
                        <a class="nav-link" routerLink="/screen/icu" target="_blank">
                            <img src="/assets/images/left-side-bar/monitoring.svg" alt="">
                            {{'ICU monitoring' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="this.rights.can(['permisssion-observe-patients-by-risk'])">
                        <a class="nav-link" routerLink="/screen/icu-high" target="_blank">
                            <img src="/assets/images/left-side-bar/monitoring.svg" alt="">
                            {{'high risk patients' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="this.rights.can(['permisssion-observe-patients-by-risk'])">
                        <a class="nav-link" routerLink="/screen/icu-medium" target="_blank">
                            <img src="/assets/images/left-side-bar/monitoring.svg" alt="">
                            {{'medium risk patients' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="this.rights.can(['permisssion-observe-patients-by-risk'])">
                        <a class="nav-link" routerLink="/screen/icu-low" target="_blank">
                            <img src="/assets/images/left-side-bar/monitoring.svg" alt="">
                            {{'low risk patients' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="this.rights.can(['permisssion-observe-patients-by-risk'])">
                        <a class="nav-link" routerLink="/screen/icu-very-low" target="_blank">
                            <img src="/assets/images/left-side-bar/monitoring.svg" alt="">
                            {{'very-low risk patients' | translate }}
                        </a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" href="/screen/nightingale" target="_blank">
                            <img src="/assets/images/left-side-bar/monitoring.svg" alt="">
                            {{'Nightingale monitoring' | translate }}
                        </a>
                    </li> -->
                    <!-- <li class="nav-item">ф
                        <a class="nav-link" routerLink="/screen/patients-overview" target="_blank">
                            <img src="/assets/images/left-side-bar/monitoring.svg" alt="">
                            {{'Patients Overview' | translate }}
                        </a>
                    </li> -->
                    <li class="nav-item" *ngIf="this.rights.can(['permission-view-observation-dashboard'])">
                        <a class="nav-link" routerLink="/screen/nightingale-ro" target="_blank">
                            <img src="/assets/images/left-side-bar/monitoring.svg" alt="">
                            {{'Observation dashboard' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="this.rights.can(['permission-view-general-alert-board'])">
                        <a class="nav-link" routerLink="/screen/alerts" target="_blank">
                            <img src="/assets/images/left-side-bar/notifications.svg" alt="">
                            {{'GENERAL ALERT BOARD' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="this.rights.can(['permission-view-tracking'])">
                        <a class="nav-link" routerLink="/maps" target="_blank">
                            <i aria-hidden="true" class="fa fa-map-marker-alt fa-lg"></i>{{'Tracking' | translate }}
                        </a>
                    </li>
                </ul>
            </ng-template>
            <ng-template [ngIf]="rights.can([
                        'permission-read-devices', 'permission-write-devices',
                        'permission-read-sim-card', 'permission-write-sim-card',
                        'permission-manage-translations',
                        'permission-write-application-version', 'permission-view-application-version'])">
                <h6
                    class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 ">
                    <span>{{'System' | translate }}</span>
                    <a class="d-flex align-items-center " href="#">
                        <span data-feather="plus-circle"></span>
                    </a>
                </h6>
                <ul class="nav flex-column">
                    <li class="nav-item"
                        *ngIf="rights.can(['permission-write-sim-card', 'permission-read-sim-card'])">
                        <a class="nav-link" routerLink="/sims" routerLinkActive="active">
                            <img src="/assets/images/left-side-bar/tasks13.png" alt="">
                            {{'Sim Cards List' | translate }}
                        </a>
                    </li>
                    <li class="nav-item"
                        *ngIf="rights.can(['permission-read-devices', 'permission-write-devices'])">
                        <a class="nav-link" routerLink="/devices" routerLinkActive="active">
                            <img src="/assets/images/left-side-bar/device_list.svg" alt="">
                            {{'Devices list' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="rights.can('permission-read-device-payment')">
                        <a class="nav-link" routerLink="/devices/device-visit" routerLinkActive="active">
                            <img src="/assets/images/left-side-bar/device_list.svg" alt="">
                            {{'Device Payment Info' | translate }}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="rights.can('permission-manage-translations')">
                        <a class="nav-link" routerLink="/translations" routerLinkActive="active">
                            <img src="/assets/images/left-side-bar/tasks11.png" alt="">
                            {{'Translations' | translate }}
                        </a>
                    </li>
                    <li class="nav-item"
                        *ngIf="rights.can('permission-write-application-version', 'permission-view-application-version')">
                        <a class="nav-link" routerLink="/phone-application-version" routerLinkActive="active">
                            <img src="/assets/images/left-side-bar/tasks11.png" alt="">
                            {{'Phone appl versions' | translate }}
                        </a>
                    </li>
                </ul>
            </ng-template>
            <ng-template [ngIf]="rights.can(['permission-view-audit-log'])">
                <h6
                        class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1">
                    <span>{{'LOGS' | translate}}</span>
                    <a class="d-flex align-items-center " href="#">
                        <span data-feather="plus-circle"></span>
                    </a>
                </h6>
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/audit-log" routerLinkActive="active">
                            <i class="far fa-file-alt"></i>
                            {{'View log ' | translate }}
                        </a>
                    </li>
                </ul>
            </ng-template>
            <ng-template [ngIf]="rights.can(['permission-manage-garmin-app-version'])">
                <h6
                        class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1">
                    <span>{{'Garmin applications' | translate}}</span>
                    <a class="d-flex align-items-center " href="#">
                        <span data-feather="plus-circle"></span>
                    </a>
                </h6>
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/garmin-app" routerLinkActive="active">
                            <i class="far fa-file-alt"></i>
                            {{'Garmin applications' | translate }}
                        </a>
                    </li>
                </ul>
            </ng-template>
        </nav>

        <main class="main-container-1">
            <nav class="top-menu">
                <div class="toggle-menu" (click)="hideSidebar = !hideSidebar">
                    <i aria-hidden="true" class="fa fa-bars fa-lg"></i>
                </div>
                <div class="page-title" *ngIf="heading">
                    {{heading | translate}}
                </div>
                <div class="menu-right">
                    <ng-chat *ngIf="showChat && medicalCenters.length" 
                        [adapter]="adapter" 
                        [userId]="user.UserID" 
                        [userFirstName]="user.UserFirstName" 
                        [userLastName]="user.UserLastName" 
                        [medicalCenters]="medicalCenters">
                    </ng-chat>
                </div>
            </nav>

            <router-outlet></router-outlet>
        </main>
    </div>
</div>