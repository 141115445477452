import { LocalStorageService } from 'ngx-webstorage';
import { ChatAdapter } from 'src/app/components/ng-chat/core/chat-adapter';
import { ChatParticipantStatus } from 'src/app/components/ng-chat/core/chat-participant-status.enum';
import { ChatParticipantType } from 'src/app/components/ng-chat/core/chat-participant-type.enum';
import { DemoAdapter } from 'src/app/components/ng-chat/demo-adapter';
import { MedicalCenterService } from 'src/app/services/medical-center-service';
import { SocketIoChatService } from 'src/app/services/socket-io.chat.service';
import { environment } from 'src/environments/environment';

export class BaseLayoutComponent {

  public showChat = false;
  public adapter: ChatAdapter;
  public medicalCenters = [];
  public user;

  public medicalCenterService: MedicalCenterService;
  private chatService: SocketIoChatService;
  protected localStorage: LocalStorageService;

  constructor(injector) {
    this.medicalCenterService = injector.get(MedicalCenterService);
    this.chatService = injector.get(SocketIoChatService);
    this.localStorage = injector.get(LocalStorageService);

    this.checkShowChat();
    this.user = this.localStorage.retrieve('loggedUser');
    this.getMedicalCenters();
  }
  
  private checkShowChat() {
    if (environment.useChat) {
      this.showChat = true;
    }
    
  }

  getMedicalCenters() {
    this.medicalCenterService.getMedicalCenters(false).subscribe((data: any) => {
      if (data.length > 0) {
        this.medicalCenters = data;
        if (this.showChat) {
            const chatConfigData = environment.useChat as any;
            const adapterMedicalCenters = this.medicalCenters.map(x => {
                return     {
                  participantType: ChatParticipantType.MedicalCenter,
                  id: x.MedicalCenterID,
                  displayName: x.MedicalCenterName,
                  avatar: null,
                  status: ChatParticipantStatus.Offline
              }
              });
            // const findCenter = adapterMedicalCenters.find(x => x.id == chatConfigData.id);
            // if (findCenter == null) {
            //   adapterMedicalCenters.push(
            //     {
            //       participantType: ChatParticipantType.MedicalCenter,
            //       id: chatConfigData.id,
            //       displayName: chatConfigData.name,
            //       avatar: null,
            //       status: ChatParticipantStatus.Offline
            //     }
            //   )
            // }
          this.chatService.connect();
          this.adapter = new DemoAdapter(adapterMedicalCenters, this.chatService.getSocket(), this.user.userMedicalCenter.MedicalCenterID);
        }
      }
    });
  }

}
