import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private openVisitID = new Subject<any>();

  constructor() { }

  openVisit(visitID) {
    this.openVisitID.next(visitID);
  }

  getVisitToOpen(): Observable<any> {
    return this.openVisitID.asObservable();
  }
}