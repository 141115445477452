import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const httpOptions = {
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {

    public constructBr = 0;

    static apiUrl = environment.protocol + environment.apiUrl;
    private apiUrlVersion = ApiService.apiUrl + '/v1/';
    private loginAut = this.apiUrlVersion + 'auth/login';
    private logoutAut = this.apiUrlVersion + 'auth/logout';
    private confirmLoginTokenAuth = this.apiUrlVersion + 'auth/confirm-token';
    private userPOSTUrl = this.apiUrlVersion + 'user/save';
    private allUsers = this.apiUrlVersion + 'user/list';
    private verifyUserEmailUrl = this.apiUrlVersion + 'user/verify-email';
    private newVerificationEmailUrl = this.apiUrlVersion + 'user/new-verification-email-send/';
    private checkResetTokenUrl = this.apiUrlVersion + 'user/check-reset-token';
    private resetPasswordUrl = this.apiUrlVersion + 'user/reset-password';
    private getUserByID = this.apiUrlVersion + 'user/';
    private delUser = this.apiUrlVersion + 'user/';
    private getScreenByRiskUrl = this.apiUrlVersion + 'user/screen-by-risk';
    private saveToScreenByRiskUrl = this.apiUrlVersion + 'user/save-to-screen-by-risk';
    private medicalCodesUrl = this.apiUrlVersion + 'medical-code';
    private medicalCentersUrl = this.apiUrlVersion + 'medical-center';
    private auditLogsUrl = this.apiUrlVersion + 'audit-entry';
    private questionUrl = this.apiUrlVersion + 'question';
    private chatMessagesUrl = this.apiUrlVersion + 'chat-messages';
    private questionaryUrl = this.apiUrlVersion + 'questionary';
    private doctorScheduleUrl = this.apiUrlVersion + 'doctor-schedule';
    private garminUrl = this.apiUrlVersion + 'garmin-app';
    private userPermissionsUrl = this.apiUrlVersion + 'user/get-user-permissions';
    private countriesUrl = this.apiUrlVersion + 'country/';
    private newsAnnotationsUrl = this.apiUrlVersion + 'news-annotation/';
    private annotationsUrl = this.apiUrlVersion + 'annotation';
    private ekgAnnotationsUrl = this.apiUrlVersion + 'ekg-annotation/';
    private noiseUrl = this.apiUrlVersion + 'noise/';
    private vitalAnalysisAlertUrl = this.apiUrlVersion + 'vital-analysis-alert/';
    private vitalParamsTresholdUrl = this.apiUrlVersion + 'vital-params-treshold/';
    private deviceVisitProgramVersionUrl = this.apiUrlVersion + 'device-visit-program-version/';
    private eventAlertUrl = this.apiUrlVersion + 'event-alert/';
    private parameterUrl = this.apiUrlVersion + 'parameter/';
    private measureParamUrl = this.apiUrlVersion + 'measure-param/';
    private purposeUrl = this.apiUrlVersion + 'purpose';
    private riskRateUrl = this.apiUrlVersion + 'risk-rate/';
    private visitRiskHistory = this.apiUrlVersion + 'visit-risk-fields-hist/';
    private patientGroupsUrl = this.apiUrlVersion + 'patient-group/';
    private citiesUrl = this.apiUrlVersion + 'city/';
    private urgentAlertTemplateUrl = this.apiUrlVersion + 'urgent-alert-template/';
    private diseasesUrl = this.apiUrlVersion + 'disease';
    private therapyUrl = this.apiUrlVersion + 'therapy/';
    private imageDiagnosticsUrl = this.apiUrlVersion + 'image-diagnostic/';
    private medicalStaffRateUrl = this.apiUrlVersion + 'medical-staff-rate/';
    private laboratoryUrl = this.apiUrlVersion + 'laboratory/';
    private microbiologyUrl = this.apiUrlVersion + 'microbiology/';
    private patientVisitPOSTUrl = this.apiUrlVersion + 'visit/save';
    private patientUrl = this.apiUrlVersion + 'patient';
    private visitUrl = this.apiUrlVersion + 'visit/';
    private endVisit = this.visitUrl + 'end-visit';
    private disconnectDevicesURL = this.visitUrl + 'disconnect-visits';
    private automaticReportOptionUrl = this.apiUrlVersion + 'automatic-report-option/';
    private automaticReportUrl = this.apiUrlVersion + 'automatic-report/';
    private visitImageDiagnosticUrl = this.apiUrlVersion + 'visit-image-diagnostic';
    private visitMedicalStaffUrl = this.apiUrlVersion + 'visit-medical-staff';
    private visitTherapyUrl = this.apiUrlVersion + 'visit-therapy';
    private visitDiseaseUrl = this.apiUrlVersion + 'visit-disease';
    private medicalStaffUrl = this.apiUrlVersion + 'medical-staff';
    private phoneAppVersionUrl = this.apiUrlVersion + 'phone-application-version';
    private deviceURL = this.apiUrlVersion + 'device';
    private simURL = this.apiUrlVersion + 'sim-card';
    private ecgArchiveURL = this.apiUrlVersion + 'ecg-archive';
    private translationsUrl = this.apiUrlVersion + 'translate';
    private getLanguagesUrl = this.apiUrlVersion + 'translate/get-languages';
    private getLanguageCategoriesUrl = this.apiUrlVersion + 'translate/get-language-categories';
    private changeUserStatusURL = this.apiUrlVersion + 'user/update-status';
    private checkUserNameURL = this.apiUrlVersion + 'user/check-user-name';
    private pathologyURL = this.apiUrlVersion + 'pathology/';
    private conclusionURL = this.apiUrlVersion + 'conclusion/';
    private shortReportURL = this.apiUrlVersion + 'short-report/';
    private manualVitalParamMeasurementURL = this.apiUrlVersion + 'manual-vital-param-measurement';
    private notificationSettingURL = this.apiUrlVersion + 'notification-setting';
    private firebaseMessageUrl = this.apiUrlVersion + 'firebase-message/';
    private visitFileReader = this.apiUrlVersion + 'visit-file-reader/';
    private permissionsUrl = this.apiUrlVersion + 'user-permission/';
    private visitNotesUrl = this.apiUrlVersion + 'visit-note';
    private eventsUrl = this.apiUrlVersion + 'event';
    private visitUserViewUrl = this.apiUrlVersion + 'visit-user-view';
    private medicalSpecialtiesUrl = this.apiUrlVersion + 'medical-specialty/';


    private urlRd = environment.protocol + environment.apiUrl + '/rd/';
    private urlReg = environment.protocol + environment.apiUrl + '/registration/';
    private techUrl = environment.protocol + environment.apiUrl + '/technical/';
    private medCenterUrl = this.urlReg + 'medicalCenters';
    private questSetUrl = this.urlRd + 'questionSet';


    private totalPatients = this.urlReg + 'allPatients';
    private patientsMeasureRisk = this.urlReg + 'patientMeasureRisk';
    private patientsActiveRisk = this.urlReg + 'patientsActiveRisk';
    private allDeviceOptionsUrl = this.urlReg + 'allDeviceOptions';

    private deviceModelsUrl = this.urlReg + 'allDeviceModels';


    private codesUrl = this.urlRd + 'codes/';
    private observationReasonsUrl = this.codesUrl + '00600';
    private observationPeriodsUrl = this.codesUrl + '00700';
    private observationTypesUrl = this.codesUrl + '00800';
    private bodyPartUrl = this.codesUrl + '01200';
    private wModeUrl = this.codesUrl + '00900';
    private bloodTypesUrl = this.codesUrl + '01000';
    private patientsUrl = this.urlReg + 'patients';
    private patientManagment = this.urlReg + 'patientManag';
    private deviceModelByIdUrl = this.urlReg + 'deviceModelById/';

    private startTherapiesByVisit = this.urlReg + 'startTherapies/';

    private startDiseasesByVisit = this.urlReg + 'startDiseases/';
    private sessionPostUrl = this.urlReg + 'session';

    private allSimsUrl = this.techUrl + 'simCards';

    private dismissDiagnoseUrl = this.urlReg + 'dismissDiagnose';
    private deleteDiagnoseUrl = this.urlReg + 'deleteDiagnose';
    private deleteTherapyUrl = this.urlReg + 'deleteTherapy';

    constructor(private http: HttpClient) {
    }


    public getVisitViews(visitID: number): Observable<any> {
      return this.http.get(this.visitUserViewUrl + '/' + visitID);
    }

    public getMedicalSpecialties(): Observable<any> {
      return this.http.get(this.medicalSpecialtiesUrl);
    }

    public saveVisitViews(data: any): Observable<any> {
      return this.http.post(this.visitUserViewUrl + '/save-data', data);
    }

    public getQuestions(data): Observable<any> {
      return this.http.post(this.questionUrl + '?expand=questionCreatedUser', data, { observe: 'response' as 'response' });
    }

    public getVisitStatistics(): Observable<any> {
      return this.http.get(this.visitUrl + 'visit-statistics');
    }

    public sendChatMessages(data): Observable<any> {
      return this.http.post(this.chatMessagesUrl + '/send-chat-messages', data);
    }

    public saveQuestion(data): Observable<any> {
      return this.http.post(this.questionUrl + '/save', data);
    }

    public deleteQuestion(id): Observable<any> {
      return this.http.delete(this.questionUrl + '/' + id);
    }

    public viewQuestion(id): Observable<any> {
      return this.http.get(this.questionUrl + '/' + id + '?expand=questionContents');
    }

    public getAllQuestions(): Observable<any> {
      return this.http.get(this.questionUrl + '/get-all-questions');
    }

    public getQuestionaries(data): Observable<any> {
      return this.http.post(this.questionaryUrl + '?expand=questionaryCreatedUser,questionaryEvent,eventVisits,eventTypeList,eventType,eventTimeLines', data, { observe: 'response' as 'response' });
    }

    public saveQuestionary(data): Observable<any> {
      return this.http.post(this.questionaryUrl + '/save', data);
    }

    public deleteQuestionary(id): Observable<any> {
      return this.http.delete(this.questionaryUrl + '/' + id);
    }

    public viewQuestionary(id): Observable<any> {
      return this.http.get(this.questionaryUrl + '/' + id + '?expand=questionaryQuestions,questionaryEvent,eventTimeLines,eventPatientGroups,eventVisits');
    }
    public getQuestionaryQuestions(id): Observable<any> {
      return this.http.get(this.questionaryUrl + '/questionary-questions/' + id);
    }

    public getGarminApps(): Observable<any> {
      return this.http.get(this.garminUrl);
    }

    public getVisitFileHeader(visitID: number, getFileSize = 0): Observable<any> {
      let url = this.visitFileReader + 'get-file-header/' + visitID + '/';
      if (getFileSize) {
        url = url + '1';
      }
      return this.http.get(url, {});
    }

    public getVisitFileData(visitID: number, data: any): Observable<any> {
      return this.http.post(this.visitFileReader + 'get-file-data/' + visitID, data);
    }

    public getVisitNotes(id): Observable<any> {
      return this.http.get(this.visitNotesUrl + '/' + id);
    }
    public viewVisitNote(id): Observable<any> {
      return this.http.get(this.visitNotesUrl + '/view/' + id + '?expand=VisitNoteCreatedAt,VisitNoteUpdatedAt,VisitNoteCreatedBy,VisitNoteUpdatedBy');
    }
    public saveVisitNote(data): Observable<any> {
      return this.http.post(this.visitNotesUrl, data);
    }
    public deleteVisitNote(id): Observable<any> {
      return this.http.delete(this.visitNotesUrl + '/' + id);
    }

    public getVisitQuestionaries(visitID): Observable<any> {
      return this.http.get(this.visitUrl + 'questionaries/' + visitID);
    }
    public getVisitQuestions(visitID, questionaryID): Observable<any> {
      let url = this.visitUrl + 'questions/' + visitID + '/';
      if (questionaryID) {
        url = url + questionaryID;
      }
      return this.http.get(url, {});
    }
    public getVisitQuestionaryAnswears(visitID, data): Observable<any> {
      return this.http.post(this.visitUrl + 'questionary-answears/' + visitID, data);
    }

    public markAsSeenNote(id, seen): Observable<any> {
      return this.http.post(this.visitNotesUrl + '/mark-as-seen/' + id, {seen: seen});
    }

    public checkResetToken(data: any): Observable<any> {
      return this.http.post(this.checkResetTokenUrl, data);
    }

    public resetPassword(data: any): Observable<any> {
      return this.http.post(this.resetPasswordUrl, data);
    }

    public getDiseases(subStr: string): Observable<any> {
      return this.http.get(this.diseasesUrl + '/' + subStr);
    }
    public getMedicalCodes(): Observable<any> {
      return this.http.get(this.medicalCodesUrl, {});
    }

    public deleteVisitTherapy(id: number): Observable<any> {
      return this.http.delete(this.visitTherapyUrl + '/' + id);
    }

    public deletePatGroup(visitID: number, patientGroupID): Observable<any> {
      return this.http.delete(this.visitUrl + 'delete-visit-patient-group/' + visitID + '/' + patientGroupID);
    }

    public uploadVisitFiles(visitID, data): Observable<any> {
      return this.http.post(this.visitUrl + 'upload-visit-files/' + visitID, data);
    }

    public getExternalVisitData(id): Observable<any> {
      return this.http.get(this.visitUrl + 'get-external-data/' + id + '?expand=visitPatient,visitDiseases,visitPurposes,visitTherapies,visitLaboratories,visitMicrobiologies,' +
      'visitGeneralRisks,visitCardiacRisks,visitImageDiagnostics,visitMedicalStaffMedicalStaff,visitInfos,visitPatientContacts,visitMedications');
    }

    public getVisitImages(visitID): Observable<any> {
      return this.http.get(this.visitUrl + 'visit-images/' + visitID);
    }

    public getVisitOverviewMainData(visitID): Observable<any> {
      //get-overview-main-data
      return this.http.get(this.visitUrl + 'get-overview-main-data/' + visitID);
    }

    public deleteVisitImage(id): Observable<any> {
      return this.http.delete(this.visitUrl + 'delete-visit-image/' + id);
    }
    public deleteVisitMedication(id): Observable<any> {
      return this.http.delete(this.visitUrl + 'delete-visit-medication/' + id);
    }
    public endVisitMedication(id): Observable<any> {
      return this.http.get(this.visitUrl + 'end-visit-medication/' + id);
    }
    public getVisitMedications(id): Observable<any> {
      return this.http.get(this.visitUrl + 'get-medications/' + id);
    }
    public deleteVisitImageDiagnose(visitID: number, imageDiagnosticID: number): Observable<any> {
      return this.http.delete(this.visitImageDiagnosticUrl + '/' + visitID + ',' + imageDiagnosticID);
    }

    public deleteVisitMedicalStaff(visitID: number, MedicalStaffID: number): Observable<any> {
      return this.http.delete(this.visitMedicalStaffUrl + '/' + visitID + ',' + MedicalStaffID);
    }

    public manageVisistsMedicalStaff(data: any): Observable<any> {
      return this.http.post(this.visitMedicalStaffUrl + '/manage-visits-medical-staff', data);
    }

    public dismissVisitTherapy(value: any): Observable<any> {
      return this.http.post(this.visitTherapyUrl + '/dismiss/' + value + '?expand=visitTherapyDismissedUser', {});
    }

    public deleteVisitDisease(id: number): Observable<any> {
      return this.http.delete(this.visitDiseaseUrl + '/' + id);
    }

    public deleteLaboratoryParam(id: number, value: number): Observable<any> {
      return this.http.delete(this.laboratoryUrl + 'delete-lab-param/' + id + '/' + value);
    }

    public deleteMicrobiologyParam(id: number, value: number): Observable<any> {
      return this.http.delete(this.microbiologyUrl + 'delete-microbiology-param/' + id + '/' + value);
    }

    public dismissVisitDisease(value: any): Observable<any> {
      return this.http.post(this.visitDiseaseUrl + '/dismiss/' + value + '?expand=visitDiseaseDismissedUser', {});
    }

    public getActiveVisits(data: any): Observable<any> {
      return this.http.post(this.visitUrl + 'active-visits', data, { observe: 'response' as 'response' });
    }

    public getClosedVisits(data: any): Observable<any> {
      return this.http.post(this.visitUrl + 'closed-visits', data, {});
    }
    
    public setVisitIcuChannel(visitID: number, channel: string): Observable<any> {
      return this.http.post(this.visitUrl + 'visit-icu-channel/' + visitID, {channel: channel});
    }

    public getActivePatientsVisits(): Observable<any> {
      return this.http.get(this.visitUrl + 'active-patients-visits', {});
    }

    public getEvents(visitID: any): Observable<any> {
      return this.http.get(this.visitUrl + 'event/' + visitID + '?expand=eventTimeLines', {});
    }

    public getAvailableDays(visitID: any, from: any, to: any, timeZone: string): Observable<any> {
      let queryParams = new HttpParams();
      queryParams = queryParams.append('from', from);
      queryParams = queryParams.append('to', to);
      queryParams = queryParams.append('timeZone', timeZone);
      return this.http.get(this.visitUrl + visitID + '/available-days', {params:queryParams});
    }

    public deleteEvent(id: any, visitID: number): Observable<any> {
      return this.http.delete(this.eventsUrl + '/' + id + '/' + visitID);
    }

    public getDoctorSchedule(medicalStaffID: any, from: any, to: any): Observable<any> {
      let queryParams = new HttpParams();
      queryParams = queryParams.append("from", from);
      queryParams = queryParams.append("to", to);
      return this.http.get(this.doctorScheduleUrl + '/' + medicalStaffID, {params:queryParams});
    }

    public saveDoctorSchedule(data: any): Observable<any> {
      return this.http.post(this.doctorScheduleUrl + '/save', data);
    }
    
    public deleteDoctorSchedule (doctorScheduleID: number): Observable<any> {
      return this.http.delete(this.doctorScheduleUrl + '/' + doctorScheduleID);
    }

    public getVisit(visitID: number): Observable<any> {
      return this.http.get(this.visitUrl + 'visit/' + visitID, {});
    }

    public deleteVisit(visitID: number): Observable<any> {
      return this.http.delete(this.visitUrl + visitID);
    }

    public getMedicalStaffRates(): Observable<any> {
      return this.http.get(this.medicalStaffRateUrl, {});

    }

    // medical codes by risk
    public getRiskRate(): Observable<any> {
      return this.http.get(this.riskRateUrl, {});
    }

    public getVisitRiskHistory(visitID: number, field: string): Observable<any> {
      return this.http.get(this.visitRiskHistory + visitID + '/' + field, {});
    }

    public getMentalStates(): Observable<any> {
      return this.http.get(this.riskRateUrl + '29', {});
    }

    public getPatientGroup(): Observable<any> {
      return this.http.get(this.patientGroupsUrl, {});
    }

    public getVisitPatientGroups(visitID): Observable<any> {
      return this.http.get(this.visitUrl + 'visit-patient-groups/' + visitID, {});
    }

    public getRace(): Observable<any> {
      return this.http.get(this.riskRateUrl + '8', {});

    }
    public getSelfRates(): Observable<any> {
      return this.http.get(this.riskRateUrl + '1', {});
    }

    public getEducation(): Observable<any> {
      return this.http.get(this.riskRateUrl + '20', {});
    }

    public getOccupations(): Observable<any> {
      return this.http.get(this.riskRateUrl + '25', {});
    }

    public getLocations(): Observable<any> {
      return this.http.get(this.riskRateUrl + '34', {});
    }

    public getOxygenList(): Observable<any> {
      return this.http.get(this.riskRateUrl + '14', {});
    }

    public getGeneralRisk(): Observable<any> {
      return this.http.get(this.riskRateUrl + '37', {});
    }

    public getCardiacRisk(): Observable<any> {
      return this.http.get(this.riskRateUrl + '54', {});
    }

    public getLaboratories(): Observable<any> {
      return this.http.get(this.laboratoryUrl, {});
    }

    public getMicrobiologies(): Observable<any> {
      return this.http.get(this.microbiologyUrl, {});
    }

    public getMedicalCenters(data?): Observable<any> {
      return this.http.post(this.medicalCentersUrl, data, { observe: 'response' as 'response' });
    }

    public getAuditLogs(data?): Observable<any> {
      return this.http.post(this.auditLogsUrl, data, { observe: 'response' as 'response' });
    }
    public getAuditLogUsers(): Observable<any> {
      return this.http.get(this.auditLogsUrl + '/users');
    }
    public auditEntryPerson(id): Observable<any> {
      return this.http.get(this.auditLogsUrl + '/audit-entry-person/' + id);
    }

    public getMedicalStaffList(data?): Observable<any> {
      return this.http.post(this.medicalStaffUrl + '/list' + '?expand=medicalStaffPerson', data, { observe: 'response' as 'response' });
    }

    public getPhoneApplicationVerions(data?): Observable<any> {
      return this.http.post(this.phoneAppVersionUrl, data, { observe: 'response' as 'response' });
    }

    public getMedicalCentersList(): Observable<any> {
      return this.http.get(this.medicalCentersUrl + '/list');
    }

    public getMedicalCenterByID(medicalCenterID: number): Observable<any> {
      return this.http.get(this.medicalCentersUrl + '/' + medicalCenterID);
    }

    public getPhoneApplicationVersionByID(id: number): Observable<any> {
      return this.http.get(this.phoneAppVersionUrl + '/' + id);
    }

    public getMedicalStaffByID(medicalStaffID: number): Observable<any> {
      return this.http.get(this.medicalStaffUrl + '/view/' + medicalStaffID + '?expand=medicalStaffPerson');
    }

    public getUserPermissions(data?): Observable<any> {
      return this.http.post(this.userPermissionsUrl, data, { observe: 'response' as 'response' });
    }

    public getContries(): Observable<any> {
      return this.http.get(this.countriesUrl);
    }

    public getNewsAnnotations(): Observable<any> {
      return this.http.get(this.newsAnnotationsUrl);
    }

    public getAnnotations(): Observable<any> {
      return this.http.get(this.annotationsUrl);
    }

    public getVisitAnnotations(visitID: number): Observable<any> {
      return this.http.get(this.annotationsUrl + '/visit-annotations/' + visitID);
    }

    public createVisitAnnotation(data: any): Observable<any> {
      return this.http.post(this.annotationsUrl + '/create-visit-annotation', data);
    }

    public deleteVisitAnnotation(id: number): Observable<any> {
      return this.http.delete(this.annotationsUrl + '/delete-visit-annotation/' + id);
    }

    public createVisitMeasure(data: any): Observable<any> {
      return this.http.post(this.annotationsUrl + '/create-visit-measure', data);
    }

    public deleteVisitMeasure(id: number): Observable<any> {
      return this.http.delete(this.annotationsUrl + '/delete-visit-measure/' + id);
    }

    public createVisitMeasureComment(data: any): Observable<any> {
      return this.http.post(this.annotationsUrl + '/create-visit-measure-comment', data);
    }

    public deleteVisitMeasureComment(id: number): Observable<any> {
      return this.http.delete(this.annotationsUrl + '/delete-visit-measure-comment/' + id);
    }

    public createVisitMeasureAmplitude(data: any): Observable<any> {
      return this.http.post(this.annotationsUrl + '/create-visit-measure-amplitude', data);
    }

    public deleteVisitMeasureAmplitude(id: number): Observable<any> {
      return this.http.delete(this.annotationsUrl + '/delete-visit-measure-amplitude/' + id);
    }

    public getVitalAnalysisPathologies(visitID: number): Observable<any> {
      return this.http.get(this.annotationsUrl + '/VitalAnalysisPathologies/' + visitID);
    }

    public getEkgAnnotations(): Observable<any> {
      return this.http.get(this.ekgAnnotationsUrl);
    }

    public getNoises(): Observable<any> {
      return this.http.get(this.noiseUrl);
    }

    public getEkgAnnotationsDataWithChilds(): Observable<any> {
      return this.http.get(this.ekgAnnotationsUrl + 'data-with-childs/');
    }

    public getVitalAnalysisAlert(visitID = null, data: any): Observable<any> {
      return this.http.post(this.vitalAnalysisAlertUrl + visitID, data);
    }

    public saveManualVitalAnalysisAlert(visitID = null, data: any): Observable<any> {
      return this.http.post(this.vitalAnalysisAlertUrl + 'create-manual-alert/' + visitID, data);
    }

    public getVitalPramsTreshHold(visitID = null): Observable<any> {
      return this.http.get(this.vitalParamsTresholdUrl + visitID);
    }

    public getVitalPramsTreshHoldByMedCenter(medicalCenterID = null): Observable<any> {
      return this.http.get(this.vitalParamsTresholdUrl + 'treshold-by-medical-center/' + medicalCenterID);
    }

    public getProgramVersions(visitID = null): Observable<any> {
      return this.http.get(this.deviceVisitProgramVersionUrl + visitID);
    }

    public saveVitalPramsTreshHold(visitID, data): Observable<any> {
      return this.http.post(this.vitalParamsTresholdUrl + 'save-data/' + visitID, data);
    }

    public saveVitalPramsTreshHoldByMedCenter(medicalCenterID, data): Observable<any> {
      return this.http.post(this.vitalParamsTresholdUrl + 'save-treshold-by-medical-center/' + medicalCenterID, data);
    }

    public getRejectedAlerts(visitID, data): Observable<any> {
      return this.http.post(this.vitalAnalysisAlertUrl + 'rejected/' +visitID, data);
    }

    public getNewVitalAnalysisAlert(visitIDs: any): Observable<any> {
      return this.http.post(this.vitalAnalysisAlertUrl + 'new', visitIDs);
    }

    public getNewEventAlert(visitIDs: any): Observable<any> {
      return this.http.post(this.eventAlertUrl + 'new', visitIDs);
    }

    public getVitalParams(): Observable<any> {
      return this.http.get(this.parameterUrl + 'get-vital-params');
    }
    public getSosPressed(visitID, data): Observable<any> {
      return this.http.post(this.visitUrl + 'get-sos-pressed/' + visitID, data);
    }

    public getParameters(): Observable<any> {
      return this.http.get(this.parameterUrl + 'get-parameters');
    }

    public getEventTypes(): Observable<any> {
      return this.http.get(this.eventsUrl + '/get-event-types');
    }

    public getMeasureParams(): Observable<any> {
      return this.http.get(this.measureParamUrl + 'get-measure-params');
    }

    public getVisitMeasureParams(visitID, data): Observable<any> {
      return this.http.post(this.measureParamUrl + 'get-visit-measure-params/' + visitID, data);
    }
    
    public getLastVisitMeasureParams(visitID): Observable<any> {
      return this.http.get(this.measureParamUrl + 'get-last-visit-measure-params/' + visitID);
    }
    
    public saveMeasureParams(visitID, data): Observable<any> {
      return this.http.post(this.measureParamUrl + 'save-measure-params/' + visitID, data);
    }

    public getPurposes(): Observable<any> {
      return this.http.get(this.purposeUrl);
    }

    public saveVitalAnalysisAlertFollowUpAction(data: any): Observable<any> {
      return this.http.post(this.vitalAnalysisAlertUrl + 'save-follow-up', data);
    }

    public saveEvent(data: any, visitID): Observable<any> {
      return this.http.post(this.eventsUrl + '/save/' + visitID, data);
    }

    public getLoginAut(username: string, password: string, recaptcha: string): Observable<any> {
      return this.http.post(this.loginAut + '?expand=object,userPatientScreens', { 'username': username, 'password': password, 'recaptcha': recaptcha });
    }

    public requestPasswordReset(data: any): Observable<any> {
      return this.http.post(this.apiUrlVersion + 'user/request-password-reset', data);
    }

    public confirmLoginToken(code): Observable<any> {
      return this.http.post(this.confirmLoginTokenAuth, { 'confirmationCode': code });
    }

    public doLogoutAut(value: any): Observable<any> {
      return this.http.post(this.logoutAut, value, httpOptions);
    }

    public getUsers(data: any): Observable<any> {
      return this.http.post(this.allUsers, data, { observe: 'response' as 'response' });
    }

    public changeUserStatus(data: any): Observable<any> {
      return this.http.post(this.changeUserStatusURL, data);
    }

    public checkUserName(data: any): Observable<any> {
      return this.http.post(this.checkUserNameURL, data);
    }

    public getDevices(data: any): Observable<any> {
      return this.http.post(
        this.deviceURL + '?expand=deviceMedicalCenter,deviceStatus,deviceMedicalStaff,deviceSPOSensorType,deviceModel,deviceSimCard',
        data,
        { observe: 'response' as 'response' }
      );
    }
    
    public getDeviceVisit(data: any, server): Observable<any> {
      var url = this.deviceURL + '/device-visit';
      if (server == 'red') {
        url += '?server=red' 
      }
      return this.http.post(
        url,
        data,
        { observe: 'response' as 'response' }
      );
    }

    public getDeviceVisitTotalCount(data: any): Observable<any> {
      return this.http.post(
        this.deviceURL + '/device-visit-total-seconds',
        data
      );
    }

    public getDeviceById(deviceId: number): Observable<any> {
      return this.http.get(this.deviceURL + '/' + deviceId);
    }

    public getSims(data: any): Observable<any> {
      return this.http.post(
        this.simURL,
        data,
        { observe: 'response' as 'response' }
      );
    }

    public deleteSim(simID: number): Observable<any> {
      return this.http.delete(this.simURL + '/' + simID);
    }

    public getSimById(deviceId: number): Observable<any> {
      return this.http.get(this.simURL + '/' + deviceId);
    }

    public saveSim(data: any): Observable<any> {
      return this.http.post(this.simURL + '/save', data);
    }

    public uploadApplication(data: any): Observable<any> {
      return this.http.post(this.simURL + '/upload-application', data, {
        reportProgress: true,
        observe: 'events'
      }).pipe(
        catchError((err: any) => {
          return throwError(err.message);
        })
      );
    }

    public uploadGarminApp(data: any): Observable<any> {
      return this.http.post(this.garminUrl + '/upload', data, {
        reportProgress: true,
        observe: 'events'
      }).pipe(
        catchError((err: any) => {
          return throwError(err.message);
        })
      );
    }

    public deleteGarminApp(data: any): Observable<any> {
      return this.http.post(this.garminUrl + '/delete-file', data);
    }

    public getSimStaticData(): Observable<any> {
      return this.http.get(this.simURL + '/static-data');
    }

    public saveToEcgArchive(visitID: number, data: any): Observable<any> {
      return this.http.post(this.ecgArchiveURL + '/save/' + visitID, data, {
        reportProgress: true,
        observe: 'events'
      }).pipe(
        catchError((err: any) => {
          return throwError(err.message);
        })
      );
    }

    public uploadEcgScreenshot(data: any): Observable<any> {
      return this.http.post(this.ecgArchiveURL + '/save-screenshot', data, {
        reportProgress: true,
        observe: 'events'
      }).pipe(
        catchError((err: any) => {
          return throwError(err.message);
        })
      );
    }
    public saveHtmlToEcgArchive(visitID: number, data: any): Observable<any> {
      return this.http.post(this.ecgArchiveURL + '/save-html/' + visitID, data);
    }

    public getEcgArchive(visitID: number): Observable<any> {
      return this.http.get(this.ecgArchiveURL + '/' + visitID);
    }
    
    public getEcgArchiveByFilter(visitID: number, data: any): Observable<any> {
      return this.http.post(this.ecgArchiveURL + '/get-archives-by-filter/' + visitID, data);
    }

    public getEcgArchiveByFilterByPage(data: any): Observable<any> {
      return this.http.post(this.ecgArchiveURL + '/get-archives-by-filter-page', data, { observe: 'response' as 'response' });
    }

    public getNextEcgArchive(data: any): Observable<any> {
      return this.http.post(this.ecgArchiveURL + '/get-next-archive', data);
    }

    public getEcgArchiveHtml(id: number): Observable<any> {
      return this.http.get(this.ecgArchiveURL + '/html-archive/' + id);
    }

    public getEcgPathologyValues(): Observable<any> {
      return this.http.get(this.conclusionURL + 'get-ecg-pathology-values');
    }

    public deleteEcgArchive(archiveID: number): Observable<any> {
      return this.http.delete(this.ecgArchiveURL + '/delete/' + archiveID, httpOptions);
    }

    public getFreeDevices(includeVisitID = null, medicalCenterID = null): Observable<any> {
      let url = this.deviceURL + '/free-devices/';
      if (includeVisitID) {
        url = url + includeVisitID;
      }
      if (medicalCenterID) {
        return this.http.get(url, {params: {medicalCenterID: medicalCenterID}});
      } else {
        return this.http.get(url);
      }
    }

    public getDeviceOptions(): Observable<any> {
      return this.http.get(this.deviceURL + '/device-options');
    }

    public checkMacAddress(data: any): Observable<any> {
      return this.http.post(this.deviceURL + '/check-mac-address', data);
    }

    public getFreeSimCards(includeSimCardID = null): Observable<any> {
      let url = this.simURL + '/free-sim-cards/';
      if (includeSimCardID) {
        url = url + includeSimCardID;
      }
      return this.http.get(url);
    }

    public deleteMultipleSims(sims: any): Observable<any> {
      return this.http.post(this.simURL + '/delete-multiple', sims);
    }

    public saveDevice(data: any): Observable<any> {
      return this.http.post(this.deviceURL + '/save', data);
    }

    public saveMedicalCenter(data: any): Observable<any> {
      return this.http.post(this.medicalCentersUrl + '/save', data);
    }
    public savePhoneApplVersion(data: any): Observable<any> {
      return this.http.post(this.phoneAppVersionUrl + '/save', data);
    }
    public saveMedicalStaff(data: any): Observable<any> {
      return this.http.post(this.medicalStaffUrl + '/save', data);
    }

    public deleteUser(userID: number): Observable<any> {
      return this.http.delete(this.delUser + userID, httpOptions);
    }

    public deleteDevice(deviceID: number): Observable<any> {
      return this.http.delete(this.deviceURL + '/' + deviceID, httpOptions);
    }

    public deleteMedicalCenter(medicalCenterID: number): Observable<any> {
      return this.http.delete(this.medicalCentersUrl + '/' + medicalCenterID);
    }

    public deleteMedicalStaff(medicalStaffID: number): Observable<any> {
      return this.http.delete(this.medicalStaffUrl + '/' + medicalStaffID);
    }

    public deletePhoneApplVersion(applVersionID: number): Observable<any> {
      return this.http.delete(this.phoneAppVersionUrl + '/' + applVersionID);
    }

    public getUserById(userId: number): Observable<any> {
      return this.http.get(this.getUserByID + userId
            + '?expand=object,person,userMedicalCenters'
      );
    }

    public getUserNotificationSettings(userId: number): Observable<any> {
      return this.http.get(this.getUserByID + 'notification-settings/' + userId);
    }

    public saveUserNotificationSettings(userId: number, data: any): Observable<any> {
      return this.http.post(this.getUserByID + 'notification-settings-update/' + userId, data);
    }

    public verifyEmail(token: any): Observable<any> {
      return this.http.post(this.verifyUserEmailUrl, token );
    }
    public newVerificationEmailSend(id: any): Observable<any> {
      return this.http.get(this.newVerificationEmailUrl + id );
    }

    public savePatientScreen(data: any): Observable<any> {
      return this.http.post(this.getUserByID + 'save-patient-screen', data);
    }

    public getScreenByRisk(): Observable<any> {
      return this.http.get(this.getScreenByRiskUrl);
    }

    public saveToScreenByRisk(data): Observable<any> {
      return this.http.post(this.saveToScreenByRiskUrl, data);
    }

    public createUserWithService(value: any): Observable<any> {
      return this.http.post(this.userPOSTUrl, value);
    }

    public getCitiesByCountryId(countryId: number): Observable<any> {
      return this.http.get(this.citiesUrl + countryId);
    }

    public getUrgentAlertTemplate(): Observable<any> {
      return this.http.get(this.urgentAlertTemplateUrl);
    }

    public getCitiyById(cityId: number): Observable<any> {
      return this.http.get(this.citiesUrl + 'view/' + cityId);
    }

    public getTherapy(parent = null): Observable<any> {
      let url = this.therapyUrl;
      if (parent) {
        url = url + parent;
      }
      return this.http.get(url);
    }

    public getImageDiagnostics(parent = null): Observable<any> {
      let url = this.imageDiagnosticsUrl;
      if (parent) {
        url = url + parent;
      }
      return this.http.get(url);
    }

    public saveVisit(value: any, medicalCenterID = null): Observable<any> {
      if (medicalCenterID) {
        return this.http.post(this.patientVisitPOSTUrl, value, {params: {medicalCenterID: medicalCenterID}});
      } else {
        return this.http.post(this.patientVisitPOSTUrl, value);
      }
    }

    public calculateRisk(value: any): Observable<any> {
      return this.http.post(this.visitUrl + 'calculate-risk', value);
    }

    public getAllPatientsAndVisits(data: any): Observable<any> {
      return this.http.post(this.patientUrl, data, { observe: 'response' as 'response' });
    }
    
    public getAllPatientsAndVisitsProgram(data: any, server): Observable<any> {
      var url = this.patientUrl + '/program';
      if (server == 'red') {
        url += '?server=red' 
      }
      return this.http.post(url, data, { observe: 'response' as 'response' });
    }

    public getVisitById(visitId: number): Observable<any> {
      return this.http.get(this.visitUrl + visitId
            + '?expand=visitPatient,deviceVisits,visitDiseases,visitPurposes,visitTherapies,visitLaboratories,visitMicrobiologies,' +
            'visitGeneralRisks,visitCardiacRisks,visitImageDiagnostics,visitMedicalStaffMedicalStaff,visitInfos,visitPatientContacts,visitMedications');
    }

    public getVisitPurposes(visitId: number): Observable<any> {
      return this.http.get(this.visitUrl + visitId
            + '?expand=visitPurposes');
    }
    public getVisitOverviewById(visitId: number): Observable<any> {
      return this.http.get(this.visitUrl + visitId
            + '?expand=visitPatient,actualDiseases,actualMedications,actualLaboratories,' +
            'visitGeneralRisks,visitCardiacRisks,visitImageDiagnostics,visitMedicalStaffNames,visitInfos,visitPatientContacts,visitDevice,patientGroups,' +
            'actualMicrobiology,historyOwnRate,visitImages, eventAlertsCount, eventReports');
    }

    public removePatientContact(id): Observable<any> {
      return this.http.delete(this.patientUrl + '/delete-patient-contact' + id);
    }

    public getConclusionByVisitId(visitId: number): Observable<any> {
      return this.http.get(this.visitUrl + visitId
            + '?expand=visitPatient,visitDevice,visitMedicalStaff,visitDiseases,visitTherapies,visitMedications');
    }


    public saveConclusion(data: any): Observable<any> {
      return this.http.post(this.conclusionURL + 'save', data);
    }

    public getConclusions(visitId): Observable<any> {
      return this.http.get(this.conclusionURL + 'list/' + visitId);
    }
    public saveShortReport(data: any): Observable<any> {
      return this.http.post(this.shortReportURL + 'save', data);
    }

    public getShortReports(visitId): Observable<any> {
      return this.http.get(this.shortReportURL + 'list/' + visitId);
    }

    public getDailyConclusions(visitId): Observable<any> {
      return this.http.get(this.conclusionURL + 'list-daily-conclusions/' + visitId);
    }

    public getConclusion(conclusionId: any): Observable<any> {
      return this.http.get(this.conclusionURL + 'view/' + conclusionId);
    }

    public downloadConclusion(conclusionID: number): any {
      return this.http.get(this.conclusionURL + 'view-conclusion/' + conclusionID,  {responseType: 'blob'});
    }

    public getShortReport(shortReportID: any): Observable<any> {
      return this.http.get(this.shortReportURL + 'view/' + shortReportID);
    }

    public getPatientsByIdent(patientIdent: string): Observable<any> {
      return this.http.get(this.patientUrl + '/' + patientIdent + '?expand=visitWeightHeight');
    }

    public getAllDoctors(medicalCenterID = null): Observable<any> {
      if (medicalCenterID) {
        return this.http.get(this.medicalStaffUrl + '/00401', {params: {medicalCenterID: medicalCenterID}});
      } else {
        return this.http.get(this.medicalStaffUrl + '/00401');
      }
    }

    public getAllDoctorsByMedicalCenter(medicalCenterID): Observable<any> {
      return this.http.get(this.medicalStaffUrl + '/00401',  {params: {medicalCenterID: medicalCenterID}});
    }

    public getMedicalStaff(): Observable<any> {
      return this.http.get(this.medicalStaffUrl + '/');
    }

    public getAllTranslations(data: any): Observable<any> {
      return this.http.post(this.translationsUrl, data, { observe: 'response' as 'response' });
    }

    public translateExternalApi(data: any): Observable<any> {
      return this.http.post(this.translationsUrl + '/translate-deepl', data);
    }

    public updateTranslation(data: any): Observable<any> {
      return this.http.post(this.translationsUrl + '/save', data);
    }

    public createTranslation(data: any): Observable<any> {
      return this.http.post(this.translationsUrl + '/create', data);
    }

    public deleteTranslation(id: any): Observable<any> {
      return this.http.delete(this.translationsUrl + '/' + id);
    }

    public getLanguages(): Observable<any> {
      return this.http.get(this.getLanguagesUrl);
    }

    public getPathology(): Observable<any> {
      return this.http.get(this.pathologyURL);
    }

    public getLanguageCategories(): Observable<any> {
      return this.http.get(this.getLanguageCategoriesUrl);
    }

    public disconnectDevice(value: any): Observable<any> {
      return this.http.post(this.endVisit, value);
    }

    public disconnectDevices(value: any): Observable<any> {
      return this.http.post(this.disconnectDevicesURL, value);
    }

    public getAutomaticReportOption(): Observable<any> {
      return this.http.get(this.automaticReportOptionUrl + '?sort=AutomaticReportOptionSort');
    }
    
    public getMinMaxReportDates(visitID): Observable<any> {
      return this.http.get(this.automaticReportUrl + 'min-max-date-by-visit/' + visitID);
    }

    public getReport(visitID, data): Observable<any> {
      return this.http.post(this.automaticReportUrl + 'get-report/' + visitID, data, {responseType: 'blob'});
    }

    public createMenualVitalParamMeasurement(data): Observable<any> {
      return this.http.post(this.manualVitalParamMeasurementURL + '/save', data);
    }

    public getVisitAssessmnet(id: any): Observable<any> {
      return this.http.get(this.visitUrl + 'get-visit-assessment/' + id, {});
    }

    public saveVisitAssessmnet(data: any): Observable<any> {
      return this.http.post(this.visitUrl + 'save-visit-assessment', data);
    }

    public getNotificationSettings(visitID: number): Observable<any> {
      return this.http.get(this.notificationSettingURL + '/' + visitID);
    }

    public saveNotificationSettings(data: any): Observable<any> {
      return this.http.post(this.notificationSettingURL + '/save', data);
    }

    public updateFirebaseToken(data: any): Observable<any> {
      return this.http.post(this.firebaseMessageUrl + 'update', data);
    }

    public deleteFirebaseToken(data: any): Observable<any> {
      return this.http.post(this.firebaseMessageUrl + 'delete-token', data);
    }

    public saveNewToken(data: any): Observable<any> {
      return this.http.post(this.firebaseMessageUrl + 'save-new-token', data);
    }


    public acceptAlert(data: any): Observable<any> {
      return this.http.post(this.vitalAnalysisAlertUrl + 'accept', data);
    }

    public clearEventAlert(eventScheduleID): Observable<any> {
      return this.http.get(this.eventAlertUrl + 'clear/' + eventScheduleID);
    }
    
    public clearAllEventAlerts(visitID): Observable<any> {
      return this.http.get(this.eventAlertUrl + 'clear-all/' + visitID);
    }

    public getAlertData(alertID: number): Observable<any> {
      return this.http.get(this.vitalAnalysisAlertUrl + 'get-alert-data/' + alertID);
    }

    public getRoles() {
      return this.http.get(this.permissionsUrl + 'list-roles');
    }
    public getPermissionsByRole(role = null) {
      if (role == null) {
        return this.http.post(this.permissionsUrl + 'list-permissions-by-role', {});
      } else {
        return this.http.post(this.permissionsUrl + 'list-permissions-by-role', {role: role});
      }
      
    }
    public getRole(role) {
      return this.http.post(this.permissionsUrl + 'role', {role: role});
    }

    public saveRole(role) {
      return this.http.post(this.permissionsUrl + 'save-role', role);
    }

    public deleteRole(role) {
      return this.http.post(this.permissionsUrl + 'delete-role', role);
    }

    public getDeviceModelById(deviceModelId: number): Observable<any> {
      return this.http.get(this.deviceModelByIdUrl + deviceModelId);
    }

    public getMedCenters(): Observable<any> {
      return this.http.get(this.medCenterUrl);
    }

    public getQuestSet(setNo: string): Observable<any> {
      return this.http.get(this.questSetUrl + '/' + setNo);
    }
    
    public getObservationReasons(): Observable<any> {
      return this.http.get(this.observationReasonsUrl);
    }

    public getObservationPeriod(): Observable<any> {
      return this.http.get(this.observationPeriodsUrl);
    }

    public getObservationType(): Observable<any> {
      return this.http.get(this.observationTypesUrl);
    }

    public getWorkingModes(): Observable<any> {
      return this.http.get(this.wModeUrl);
    }

    public getBloodTypes(): Observable<any> {
      return this.http.get(this.bloodTypesUrl);
    }

    public getGenders(): Observable<any> {
      return this.http.get(this.riskRateUrl + '?parent=17', {});
    }

    public getPatients(): Observable<any> {
      return this.http.get(this.patientsUrl);
    }

    public getPatientManagment(medCenter: number): Observable<any> {
      return this.http.get(this.patientManagment + '/' + medCenter);
    }

    public getBodyParts(): Observable<any> {
      return this.http.get(this.bodyPartUrl);
    }

    public getDeviceModels(): Observable<any> {
      return this.http.get(this.deviceModelsUrl);
    }

    public getAllSimCards(): Observable<any> {
      return this.http.get(this.allSimsUrl);
    }

    public getDeviceOption(): Observable<any> {
      return this.http.get(this.allDeviceOptionsUrl);
    }


    createSessionWithService(value: any): Observable<any> {
      return this.http.post(this.sessionPostUrl, value, httpOptions);
    }


    public getPatientsMeasureRisk(medicalCenterId): Observable<any> {
      return this.http.get(this.patientsMeasureRisk + '/' + medicalCenterId);
    }


    public getPatientsActiveRisk(medicalCenterId): Observable<any> {
      return this.http.get(this.patientsActiveRisk + '/' + medicalCenterId);
    }


    getStartThAndDisByVisit(visitId: number): Observable<any> {

      const response1 = this.http.get(this.startTherapiesByVisit + visitId);
      const response2 = this.http.get(this.startDiseasesByVisit + visitId);

      return forkJoin([response1, response2]);

    }

    public dismissDiagnose(value: any): Observable<any> {
      return this.http.put(this.dismissDiagnoseUrl, value, httpOptions);
    }

    public deleteDiagnose(startDiagnoseId: number): Observable<any> {
      return this.http.delete(this.deleteDiagnoseUrl + '/' + startDiagnoseId, httpOptions);
    }

    public deleteTherapy(startTherapyId: number): Observable<any> {
      return this.http.delete(this.deleteTherapyUrl + '/' + startTherapyId, httpOptions);
    }
    public returnEcgToConclusion(visitID: number): Observable<any> {
      return this.http.get(this.ecgArchiveURL + '/return-to-conclusion/' + visitID, httpOptions);
    }

}
