<link *ngIf="customTheme" rel="stylesheet" [href]='customTheme | sanitize'>
<ng-container *ngIf="!isDisabled && isBootstrapped && !unsupportedViewport">
    <div class="chat_icon" (click)="collapse()" (dblclick)="doubleClick()">
        <img class="icon" src="/assets/images/chat_icon.svg">
        <i class="fa fa-ellipsis-h"> </i>
        <div *ngIf="this.totalMessages > 0" class="chat-messages-count">{{this.totalMessages > 99 ? '99+' : this.totalMessages}}</div>
    </div>
    <div id="ng-chat" #chatContainer resizeObserver (resize)="onResize($event)" class="{{theme}}" [ngClass]="{'open': !isCollapsed, 'primary-background': true, 'ng-full': fullChat && !isMobileApp}">
        <div></div>
        <ng-chat-friends-list
            [localization]="localization"
            [shouldDisplay]="!hideFriendsList"
            [userId]="userId"
            [userFirstName]="userFirstName" 
            [userLastName]="userLastName" 
            [isCollapsed]="isCollapsed"
            [searchEnabled]="searchEnabled"
            [participants]="participants"
            [participantsResponse]="participantsResponse"
            [participantsInteractedWith]="participantsInteractedWith"
            [windows]="windows"
            [totalMessages]="totalMessages"
            [currentActiveOption]="currentActiveOption"
            (onParticipantClicked)="onParticipantClickedFromFriendsList($event)"
            (onOptionPromptCanceled)="onOptionPromptCanceled()"
            (onOptionPromptConfirmed)="onOptionPromptConfirmed($event)"
            (onToggleFullScreen)="toggleFullScreen()"
            (onNewMessageSoundPlay)="emitNewMessageSound()"
        >
        </ng-chat-friends-list>
        
        <ng-container *ngFor="let window of windows; let i = index">
            <ng-chat-window
                #chatWindow
                [fileUploadAdapter]="fileUploadAdapter"
                [localization]="localization"
                [userId]="userId"
                [theme]="theme"
                [window]="window"
                [showOptions]="groupAdapter"
                [emojisEnabled]="emojisEnabled"
                [linkfyEnabled]="linkfyEnabled"
                [showMessageDate]="showMessageDate"
                [messageDatePipeFormat]="messageDatePipeFormat"
                [hasPagedHistory]="hasPagedHistory"
                (onMessagesSeen)="onWindowMessagesSeen($event)"
                (onMessageSent)="onWindowMessageSent($event)"
                (onMessageHide)="onWindowMessageHide($event)"
                (onMessageDelete)="onWindowMessageDelete($event)"
                (onMessageUnHide)="onWindowMessageUnHide($event)"
                (onTabTriggered)="onWindowTabTriggered($event)"
                (onChatWindowClosed)="onWindowChatClosed($event)"
                (onOptionTriggered)="onWindowOptionTriggered($event)"
                (onLoadHistoryTriggered)="onLoadHistoryTriggered($event)"
                (onToggleFullScreen)="toggleFullScreen()"
                [(readyToMarkAsSeen)] = "readyToMarkAsSeen"
            >
            </ng-chat-window>
        </ng-container>
    </div>
</ng-container>